'use strict';

function ready(fn) {
    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function initCustom() {
    let phoneLinks = document.getElementsByClassName('phone-link');
    if (phoneLinks) {
        Array.from(phoneLinks).forEach(function (link) {
            if (link.href.length) {
                link.href = link.href.replace(/[()\-\s\t\n_+]/gi, '');
            }
        });
    }
}

ymaps.ready(initContactsMap);

function initContactsMap() {
    if (document.getElementById('map')) {
        let contactsMap = new ymaps.Map("map", {
            center: [55.532197, 37.448054],
            controls: ['zoomControl'],
            zoom: 15
        });
        contactsMap.behaviors.disable('scrollZoom');
        let officePlacemark = new ymaps.Placemark(
            [55.532197, 37.448054],
            {iconContent: 'ИЗПЦ «Юго-Запад»'},
            {preset: 'islands#redStretchyIcon'}
        );
        contactsMap.geoObjects.add(officePlacemark);
    }
}

ready(initCustom());
